import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';

export const useLocalePrefixForHref = (str: string) => {
  const authStore = useAuthStore();
  const { getUserData } = storeToRefs(authStore);
  const { $i18n } = useNuxtApp();

  return getUserData.value.locale === $i18n.defaultLocale
    ? str
    : `/${getUserData.value.locale}${str}`;
};